import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import '../styles/contact.scss'
import Seo from '../components/seo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

export default function Contact() {

    useEffect(() => {
        onmousemove = (ev) => {
            const cursor = document.querySelector('#id_cursor')
            const cursorPoint = document.querySelector('#id_cursor_point')

            cursor.style.top = `${ev.pageY}px`
            cursor.style.left = `${ev.pageX}px`
            cursorPoint.style.top = `${ev.pageY}px`
            cursorPoint.style.left = `${ev.pageX}px`
        }
    }, [])

    const [formState, setFormState] = useState({
        name: "",
        email: "",
        tel: "",
        message: ""
    })

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleChange = e => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...formState })
        })
            .then(() => alert("Votre message a bien été envoyé."))
            .catch(error => alert(error));

        e.preventDefault();
    }

    return (
        <Layout>
            <Seo title="Contact" />
            <span id="id_cursor" className="cursor"></span>
            <span id="id_cursor_point" />

            {/* <section className="section-header">
                <StaticImage
                    src="../images/logob128.png"
                    alt=""
                    placeholder="blurred"
                />
                <h1>Contact</h1>
            </section> */}

            <div className="contact">
                <section>
                    <div className="formulaire_contact flex-wrap">
                        <div className="coordonnees-contact width33">
                            <div className="blurb">
                                <h3>Email</h3>
                                <a href="mailto:contact@pixeldeveloppement.fr"><FontAwesomeIcon icon={faEnvelope} />contact@pixeldeveloppement.fr</a>
                            </div>
                            <div className="blurb">
                                <h3>Horaires d'ouverture</h3>
                                <p className="text">Du lundi au vendredi</p>
                                <p className="text">09:00 - 12:00</p>
                                <p className="text">14:00 - 19:00</p>
                            </div>
                            <div className="blurb">
                                <a href="tel:+33782403696" className="secondary_button"><FontAwesomeIcon icon={faPhone} className="rotate-90" />07 82 40 36 96</a>
                            </div>
                        </div>
                        <div className="formulaire width66">
                            <h2>Envoyez votre demande</h2>
                            <form id="id_form_contact" className="form_contact" name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={handleSubmit}>
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="formulaire"
                                />
                                <div className="input_contact">
                                    <input
                                        id="name"
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                        value={formState.name}
                                        placeholder="Nom et Prénom *"
                                        required
                                    />
                                </div>
                                <div className="input_contact half">
                                    <input
                                        id="email"
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        value={formState.email}
                                        placeholder="Email *"
                                        required
                                    />
                                </div>
                                <div className="input_contact half">
                                    <input
                                        id="tel"
                                        type="tel"
                                        name="tel"
                                        onChange={handleChange}
                                        value={formState.tel}
                                        placeholder="Téléphone *"
                                        required
                                    />
                                </div>
                                <div className="message">
                                    <textarea
                                        className="message_contact"
                                        name="message"
                                        placeholder="Message *"
                                        onChange={handleChange}
                                        required>
                                    </textarea>
                                </div>
                                <button type="submit" className="button-submit">
                                    Envoyer
                                </button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>

        </Layout>
    )
}